<template>
  <div class="auth-form-box">
    <div class="header">
      填写认证信息
    </div>
    <div class="form-content">
      <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol" ref="form">
        <a-form-item v-for="(item, index) in formData" :key="index" :label="item.name">
          <!-- 1=单行输入框,2=多行输入框,3=数字,4=金额,5=单选,6=多选,7=日期,8=时间,9=图片,10=附件,11=下拉框,12=地址选择,13=网址,14级联 -->
          <div v-if="item.type === 1">
            <a-input
            :placeholder="item.remark"
            v-decorator="handleItemDecorator(item)"
            :maxLength='handleMaxLength(item)'
            />
          </div>

          <div v-if="item.type === 2">
            <a-textarea
              :placeholder="item.remark"
              v-decorator="handleItemDecorator(item)" style="height: 100px;" :maxLength='handleMaxLength(item)'/>
          </div>

          <div v-if="item.type === 5">
            <a-radio-group
              v-decorator="handleItemDecorator(item)">
              <a-radio v-for="(opItem, opIndex) in JSON.parse(item.options)" :key="opIndex" :value="opItem.value">
                {{opItem.value}}
              </a-radio>
            </a-radio-group>
          </div>

          <div v-if="item.type === 6">
            <a-checkbox-group
              v-decorator="handleItemDecorator(item)">
              <a-checkbox  v-for="(opItem, opIndex) in JSON.parse(item.options)" :key="opIndex" :value="opItem.value">{{opItem.value}}</a-checkbox>
            </a-checkbox-group>
          </div>

          <!-- 下拉框 -->
          <div v-if="item.type === 11">
            <a-select
            :placeholder="item.remark"
            v-decorator="handleItemDecorator(item)">
              <a-select-option v-for="(opItem, opIndex) in JSON.parse(item.options)" :key="opIndex" :value="opItem.value">
                {{opItem.value}}
              </a-select-option>
            </a-select>
          </div>

          <!-- 文件上传 -->
          <div v-if="item.type === 9 || item.type === 10">
            <upload :type="item.type" v-decorator="handleItemDecorator(item)" @imgChange="(val) => imgChange(val, item)" :tips="item.remark"></upload>
          </div>
        </a-form-item>

        <a-form-item
          :wrapper-col="{
            xs: { span: 4 },
            sm: { span: 19, offset: 5 },
          }"
        >
          <a-button type="primary" style="margin-right: 16px;" @click="handleSubmit">
            提交认证
          </a-button>
          <a-button @click="cancel">
            取消
          </a-button>
        </a-form-item>
      </a-form>  
    </div>
  </div>
</template>
<script>
import upload from './components/upload.vue'
export default {
  components: {
    upload
  },
  data () {
    return {
      detailData: {},
      formData: {},
      form: this.$form.createForm(this, { }),
      labelCol: {
        xs: { span: 4 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 9 },
        sm: { span: 19 },
      },
      formUserId: null,
      realNameInfo: {}
    }
  },
  created () {
    this.getForm()
  },
  methods: {
    handleMaxLength (item) {
      let limit = 255
      if (item.map_col && item.map_col === 'cert_name') {
        limit = 30
      }
      return limit
    },
    async getAuthList () {
      const res = await AuthService.applyCertList()
      this.realNameInfo = res.realName || {}
      if (res.type == 2 || res.type === 3) {
        const certItem = res.cert.filter(i => i.id == this.$route.query.id)[0] || {}
        const formItem = certItem.cert_info.filter(i => i.id == this.$route.query.certItemId)[0] || {}
        const formData = formItem.form_user_option
        this.formUserId = formItem.form_user_id
        const formObj = {}
        formData.forEach(i => {
          formObj[i.relation_key] = i.type === 6 ? i.value.split(',') : i.value
        })
        this.form.setFieldsValue(formObj)
      }
    },

    handleSubmit () {
      this.form.validateFields(async (err, value) => {
        if (!err) {
          const params = []
          for (const i in this.formData) {
            const obj = {
              ...this.formData[i],
              value: this.formData[i].type === 6 ? value[this.formData[i].relation_key].join(',') : value[this.formData[i].relation_key]
            }
            params.push(obj)
          }
          let param = {}
          if (this.$route.query.certItemId) {
            param = {
              form_user_id: this.formUserId,
              data: JSON.stringify([params])
            }
          } else {
            param = {
              data: JSON.stringify([params])
            }
          }
          console.log(param)
          try {
            await AuthService.commitCertInfo(param)
            this.$message.success('提交成功')
            this.$router.push('/bpy_auth')
          } catch (e) {
            console.log(this.realNameInfo)
            if (e.errCode === 536 && this.realNameInfo.status !== 1) {
              this.$router.push({
                path: '/bpy_realNameInfo'
              })
            }
          }
        }
      })
    },

    // 获取表单结构
    async getForm () {
      const res = await AuthService.getCertConfigListFromApplyCertList({cert_type: this.$route.query.type })
      const item = res.filter(i => Number(i.id) === Number(this.$route.query.id))[0]
      this.detailData = item
      this.formData = item.form.form_template_option
      if (this.$route.query.isEdit) {
        this.getAuthList()
      }
    },

    handleItemDecorator (item) {
      return [item.relation_key,{ rules: [{ required: item.is_required, message: item.remark || this.defaultMeaasge(item.type) }] }]
    },

    defaultMeaasge (type = 1) {
      let text = '请填写'
      // 1=单行输入框,2=多行输入框,3=数字,4=金额,5=单选,6=多选,7=日期,8=时间,9=图片,10=附件,11=下拉框,12=地址选择,13=网址,14级联
      switch (type) {
        case 1,2,3,4,13: 
          text = '请填写'
          break;
        case 5,6,7,8,11,12,14: 
          text = '请选择'
          break;
        case 9,10:
          text = '请上传'
          break;
      }
      return text
    },

    cancel () {
      this.$router.go(-1)
    },

    imgChange (val,item) {
      const obj = {}
      obj[item.relation_key] = val
      this.form.setFieldsValue(obj)
    }
    
  }
}
</script>
