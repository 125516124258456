<template>
  <div>
    <div class="no_img_url" v-if="!current_value" @click="selectFile()">
      <div class="up-icon">
        <img src="/images/icon-imageadd.png" alt="" srcset="" />
      </div>
      <span>{{type == 9 ? '上传图片' : '上传文件' }}</span>
    </div>
    <div class="has_img_url" v-if="current_value">
      <img :src="current_value" alt="">
      <div class="edit-mask">
        <p class="lookurl" @click="preView(current_value)">查看图片</p>
        <p class="lookline"></p>
        <p class="reUpdate" @click="selectFile()">重新上传</p>
      </div> 
    </div>
    <p class="upload-tips">{{tips}}</p>
  </div>
</template>
<style scoped>

</style>
<script>
  export default {
    name: 'Upload',
    props: {
      value: {
        type: [String],
        default: ''
      },
      // 文件上传类型
      type: {
        type: [String, Number],
        default: 1
      },
      text: {
        type: [String],
        default: ''
      },
      tips: {
        type: String
      }
    },
    data () {
      return {
        current_value: '',
      }
    },
    watch: {
      value: {
        handler(val) {
          if (val === this.current_value) {
            return
          }
          this.current_value = val;
        },
        immediate: true
      },
      current_value: {
        handler(val) {
          if (val === this.val) {
            return
          }
          this.$emit('input', val)
        }
      }
    },
    methods: {
      selectFile() {
        Upload2Oss({check_type: 2, max_size: 5}, (res) => {
          console.log(res)
          if (res && res[0]) {
            this.current_value = res[0].url
            this.$emit('imgChange', this.current_value)
          }
        })
      },
      preView() {
        this.$ImgPreview({urls: [this.current_value]})
      }
    }
}
</script>
